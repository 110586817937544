import React from 'react';
import "../styles/agenda.scss";
import Layout from "../components/layout";
import "../styles/tailwind.css";
import { motion } from "framer-motion";
import { useStaticQuery, graphql } from 'gatsby';
import { useState } from 'react';
import { nanoid } from 'nanoid';
import {Footer3} from "../components/footer3";
import useDate from "react-use-date";
import LazyHero from 'react-lazy-hero';



const Agenda = () => {

    const data = useStaticQuery(graphql`
    query getme {
        allAirtable(sort: {fields: data___startTime}) {
          nodes {
            data {
              speaker
              country
              startTime
              finishTime
              track
              topic
            }
          }
        }
      }
    `)   

const {allAirtable:{nodes}} = data;

const initialState = {
  talks: nodes,
  filter:'',
  speakerfilter:''
}


const [state, setState] = useState(initialState);


//console.log(state);

const {talks, filter, speakerfilter } = state;
let showTalks = talks;

//console.log(showTalks);

if (filter) {
 /*  console.log(filter); */
  /* console.log(talks); */
  showTalks = talks.filter((node) => {
   return node.data.country.includes(filter) 
  });

}



if (speakerfilter) {
  /*  console.log(filter); */
   /* console.log(talks); */
   showTalks = talks.filter((node) => {
    return node.data.speaker.includes(speakerfilter) 
   });
 
 }
const handleChange = (e) => {
  /* console.log(e.target.value) */
  setState({
    talks:nodes, 
    filter: e.target.value,
    speakerfilter:''
  });

}

const handleSpeakerChange = (e) => {
  /* console.log(e.target.value) */
  setState({
    talks:nodes, 
    filter: "",
    speakerfilter: e.target.value
  
  });
}

const dayOne = showTalks.slice(0,6)
const dayTwo =  showTalks.slice(6,12)

//convert date to sting
const dateConvert = (string) => {
  const time = new Date(string);
  return time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
}
//gettime in milliseconds
const miliConvert = (string) => {
  const milli = new Date(string);
  return milli.getTime();
}
//compare time
const compareTime = (curr,start,end) => {
    return curr>start && end>curr
}
//get date
const date = useDate({ interval: 'minute' });
    return (
        <Layout>
            <div className="agendatainer">
            <LazyHero 
            imageSrc="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1601709850/next-legal/panel_eu4clu.jpg"
            minHeight="20vh"
            color="#041328"
            opacity={0.7}
            parallaxOffset={50}
            isCentered={true}
            className="hero"
            children={
              <>
              <motion.div
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: 100, opacity: 0 }}
              transition={{
                duration: 1,
                ease: [0.6, 0.05, -0.01, 0.9],
              }}
              /* sm:text-lg md:text-xl lg:text-2xl xl:text-3xl */
              className="hero_Content flex flex-col items-center "> 
              <h1 className="copy_theme_h  text-gray-100 sm:text-4xl md:text-4xl lg:text-3xl xl:text-6xl text-4xl">Discover Two days of exceptional content</h1>
                 <h1 className="copy_theme text-gray-100 sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl">23rd to 24th October, All times in EAT</h1>
                </motion.div>
              </>
            }
            >
            </LazyHero>
            <motion.div 
               animate={{y:0 , opacity: 1}}
               initial={{ y: 72, opacity: 0 }}
               transition={{
                 duration: 0.5,
                 ease: [0.6, 0.05, -0.01, 0.9],
               }}
            className="ss-container mt-12 ">
            <div className="s-sessions">
            <h2 className="s-day">Day One</h2>
            <div className="s-session">
                            <span className="time">2:00 PM - 2:15 PM</span>
                            <div className="sess-title-box">
                                <h2 className="sess-title text-xl font-bold">Bytelex keynote</h2>
                                <h2 className="sess-title mt-2">Raymond Asiimwe</h2>
                            </div>
              </div>
              <div className="s-session">
                            <span className="time">2:15 PM - 2:55 PM</span>
                            <div className="sess-title-box">
                                <h2 className="sess-title text-xl font-bold">Alternative Financing Strategies; Rethinking Financing for Rwandan high growth emerging companies.</h2>
                                <h2 className="sess-title mt-2">Olivia Zank, BeneFactors</h2>
                            </div>
              </div>
              <div className="s-session">
                            <span className="time">2:55 PM - 3:55 PM</span>
                            <div className="sess-title-box">
                                <h2 className="sess-title text-xl font-bold">Shaping Kenya Startup Policy; A discussion on building an efficient ecosystem through engaging startup players in policy making.</h2>
                                <h2 className="sess-title mt-2">Abraham Muthia, Uzapoint, Favour Chananel Ruhiu, Soofa Pay, Valentino Shimanyi, Sisitech Ltd </h2>
                            </div>
              </div>
              <div className="s-session">
                            <span className="time">3:55 PM - 4:55 PM</span>
                            <div className="sess-title-box">
                                <h2 className="sess-title text-xl font-bold">Build your own legal products for passive income and productivity</h2>
                                <h2 className="sess-title mt-2">Dorna Moini, Documate</h2>
                            </div>
              </div>
              <div className="s-session">
                            <span className="time">4:55 PM - 5:25 PM</span>
                            <div className="sess-title-box">
                                <h2 className="sess-title text-xl font-bold">Is Angel Investing in Africa bridging the gap for early stage financing?</h2>
                                <h2 className="sess-title mt-2">Abu Cassim (Jozi Angels)</h2>
                            </div>
              </div>
              <div className="s-session">
                            <span className="time">5:25 PM - 6:00 PM</span>
                            <div className="sess-title-box">
                                <h2 className="sess-title text-xl font-bold">The Rise of Fintechs, Soaring above regulatory uncertainty and limitations</h2>
                                <h2 className="sess-title mt-2">Ben Lyon (Hoover)</h2>
                            </div>
              </div>
              <div className="s-session">
                            <span className="time">6:00 PM - 7:00 PM</span>
                            <div className="sess-title-box">
                                <h2 className="sess-title text-xl font-bold">Africa and its Diaspora collaborating to Overcome the seen and unseen challenges to African public and private sector digital transformation.</h2>
                                <h2 className="sess-title mt-2">Raymond Asiimwe, Kendrall Felder and Larry Yon</h2>
                            </div>
              </div>
             {/*    {dayOne.map((node) => (
                        <div key={nanoid()} className="s-session">
                            <span className="time">{`${dateConvert(node.data.startTime)} - ${dateConvert(node.data.finishTime)}`}</span>
                            <div className="sess-title-box">
                                <h2 className="sess-title text-xl font-bold">{node.data.topic}</h2>
                                <h2 className="sess-title mt-2">{node.data.speaker}</h2>
                            </div>
                        </div>
                )
                )} */}
           </div>

           <div className="s-sessions s-sessions2">
            <h2 className="s-day">Day Two</h2>
              <div className="s-session">
                              <span className="time">11:00 AM - 12:00 PM</span>
                              <div className="sess-title-box">
                                  <h2 className="sess-title text-xl font-bold">Building a resilient tech company; A founder’s perspective.</h2>
                                  <h2 className="sess-title mt-2">Dickson Mushabe, Hostalite Uganda.</h2>
                              </div>
                </div>
                <div className="s-session">
                              <span className="time">12:00 PM - 12:40 PM</span>
                              <div className="sess-title-box">
                                  <h2 className="sess-title text-xl font-bold">Startup Financing in the Post Covid Era: Looking Through the Lens of Venture Capitalists</h2>
                                  <h2 className="sess-title mt-2">Damilola Thompson, Echo VC</h2>
                              </div>
                </div>
                <div className="s-session">
                              <span className="time">12:40 PM - 1:30 PM</span>
                              <div className="sess-title-box">
                                  <h2 className="sess-title text-xl font-bold">What Legal consumers want from law firms in 2020</h2>
                                  <h2 className="sess-title mt-2">Jared Coreira</h2>
                              </div>
                </div>
                <div className="s-session">
                              <span className="time">1:30 PM - 2:15 PM</span>
                              <div className="sess-title-box">
                                  <h2 className="sess-title text-xl font-bold">Leveraging Diaspora Capital for African high growth companies</h2>
                                  <h2 className="sess-title mt-2">Samuel Kitara, Yale Africa Startup Review and Larry Yon, B & C International</h2>
                              </div>
                </div>
             {/*    {dayTwo.map((node) => (
                        <div key={nanoid()} className="s-session">
                            <span className="time">{`${dateConvert(node.data.startTime)} - ${dateConvert(node.data.finishTime)}`}</span>
                            <div className="sess-title-box">
                                <h2 className="sess-title text-xl font-bold">{node.data.topic}</h2>
                                <h2 className="sess-title mt-2">{node.data.speaker}</h2>
                            </div>
                        </div>

                )
                )} */}
           </div>
           </motion.div>
           </div>
           <Footer3/>
           </Layout>
        
    )
}

export default Agenda
